'use strict';

define("Wallbox2ControlStateContainer", ["MeterV2ControlStateContainer"], function (MeterV2ControlStateContainer) {
    return class Wallbox2ControlStateContainer extends MeterV2ControlStateContainer {
        constructor(control) {
            super(control);


            this._boundRegFn = () => {
                let tracker = control.getTrackerControl();
                tracker && SandboxComponent.registerForStateChangesForUUID(tracker.uuidAction, this, this._onTrackerStateChanged.bind(this));
            };
            CompChannel.on(CCEvent.StateContainersCreated, this._boundRegFn);
        }

        destroy() {
            CompChannel.off(CCEvent.StateContainersCreated, this._boundRegFn);
            let tracker = control.getTrackerControl();
            tracker && SandboxComponent.unregisterForStateChangesForUUID(tracker.uuidAction, this);

            super.destroy(...arguments);
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);

            this.states.carConnected = newVals[this.control.states.connected];
            if (!this.control.carConnectedInfoAvailable) {
                this.states.carConnected = true; // assume it is.
            }
            this.states.phaseSwitching = newVals[this.control.states.phaseSwitching];
            this.states.chargingEnabled = newVals[this.control.states.enabled];
            this.states.loadSheddingActive = newVals[this.control.states.loadshed];
            this.states.limitModeId = newVals[this.control.states.mode];
            this.states.unplugLimitModeId = newVals[this.control.states.modeUnplug];
            this.states.active = newVals[this.control.states.active];
            if (!this.states.unplugLimitModeId) { // if not provided, assume "keep current"
                this.states.unplugLimitModeId = 0;
            }
            this.states.limit = newVals[this.control.states.limit];
            this.states.session = this._prepareSession(newVals[this.control.states.session].text);
            if (!this.states.session && !this.control.carConnectedInfoAvailable) {
                // when no charging info is available, there may also not be a session, create one for the app.
                this.states.session = {
                    energy: this.states.totalValue,
                    power: this.states.actualValue
                }
            }

            this.states.modes = this._prepareModesMap(newVals[this.control.states.modes].text);
            // generated states
            this.states.limitMode = this.control.getLimitModeObj(this.states.limitModeId, false);
            this.states.unplugLimitMode = this.control.getLimitModeObj(this.states.unplugLimitModeId);

            this.states.isCharging = Feature.WALLBOX2_UPDATED_BLOCK ? this.states.active : this.states.chargingEnabled;
            if (this.states.session) {
                this.states.isPaused = this.states.carConnected && !this.states.chargingEnabled && this.states.session.energy > 0;
                this.states.readyForCharging = this.states.carConnected && !this.states.chargingEnabled && this.states.session.connect === 0;
            } else {
                this.states.isPaused = false;
                this.states.readyForCharging = false;
            }
            this.states.isBelowLimit = this.states.chargingEnabled && this.states.limit < this.control.minChargingPower;

            // connected since duration.
            this._updateDuration();
            if (this.states.session && this.states.session.connect > 0 && this.states.carConnected) {
                this._updateDurationInterval = this._updateDurationInterval || setInterval(this._updateDuration.bind(this, true), 1000);
            } else {
                this._updateDurationInterval && clearInterval(this._updateDurationInterval);
                this._updateDurationInterval = null;
            }

            // wallbox manager integration
            if (Feature.WALLBOX_EM) {
                this.states.priority = newVals[this.control.states.priority];
                this.states.pricePerHour = newVals[this.control.states.pricePerHour];
                this.states.pricePerKWh = newVals[this.control.states.pricePerkWh];
                this.states.limitedBy = newVals[this.control.states.limitedBy];

                if (this.states.limit === 0 && this.control.isManaged && this.states.isCharging) {
                    this.states.notEnoughPowerAssigned = true;
                } else {
                    this.states.notEnoughPowerAssigned = false;
                }
            } else {
                this.states.priority = false;
                this.states.pricePerHour = 0;
                this.states.pricePerKWh = 0;
                this.states.limitedBy = 0;
                this.states.notEnoughPowerAssigned = false;
            }
        }

        getStateColor() {
            let color;
            if (this.states.universalIsLocked) {
                // no color
            } else if (this.states.isCharging) {
                color = window.Styles.colors.stateActive;
            } else if (this.states.carConnected) {
                color = window.Styles.colors.stateActive;
            }
            return color;
        }

        getStateTextColor() {
            if (this.states?.loadSheddingActive || this.states?.phaseSwitching) {
                return window.Styles.colors.orange;

            } else if (this.states.chargingEnabled && this.states.carConnected && !this.states.isCharging) {
                return null; // maybe done with charging - maybe not yet charging?
            }

            return this.getStateColor();
        }

        getStateIconSmall() {
            if (this.states?.loadSheddingActive || this.states?.phaseSwitching) {
                return {
                    iconSrc: Icon.Wallbox2.SmallPause,
                    color: window.Styles.colors.orange
                }
            } else if (this.states?.isBelowLimit || this.states?.notEnoughPowerAssigned) {
                return {
                    iconSrc: Icon.Wallbox2.SmallFlash,
                    color: window.Styles.colors.orange
                }
            } else if (this.states?.isPaused) {
                return {
                    iconSrc: Icon.Wallbox2.SmallPause,
                    color: window.Styles.colors.stateActive
                }
            } else {
                return super.getStateIconSmall(...arguments);
            }
        }

        getStateIcon() {
            return Icon.Wallbox2.ECar
        }

        getStateTextForContent() {
            let text;

            if (this.states?.loadSheddingActive && this.states?.carConnected) {
                text = _("controls.wallbox2.paused-due-to-load-shedding")
            } else if (this.states?.phaseSwitching) {
                text = _("controls.wallbox2.paused-due-to-phase-switch");
            } else if (this.states?.notEnoughPowerAssigned) {
                text = _("controls.wallbox2.not-enough-power-available");
            } else if (this.states?.isBelowLimit) {
                text = _("controls.wallbox2.below-limit-of", {
                    minValue: lxUnitConverter.convertAndApply(this.control.actualFormat, this.control.minChargingPower)
                });
            } else if (this.states?.isCharging) {
                text = _("controls.wallbox2.charging-active");
            } else if (this.states?.carConnected) {
                text = _("controls.wallbox2.vehicle-connected");
            } else if (this.states?.isPaused) {
                text = _("controls.wallbox2.paused");
            } else {
                text = this.getStateText();
            }

            return text;
        }

        getStateText() {
            let result,
                sessionEnergy = this.states.session ? lxUnitConverter.convertAndApply(this.control.totalFormat, this.states.session.energy) : null,
                sessionPower = this.states.session ? lxUnitConverter.convertAndApply(this.control.actualFormat, this.states.actualValue) : null;
            if (this.states.loadSheddingActive && this.states.carConnected) {
                result = _("controls.wallbox2.load-shedding") + (sessionEnergy ? (SEPARATOR_SYMBOL + sessionEnergy) : "");
            } else if (this.states?.phaseSwitching) {
                result = _("controls.wallbox2.phase-switching")
            } else if (this.control.getMeterIsConnected && this.control.getCpIsConnected && this.states.isCharging) {
                result = sessionPower + SEPARATOR_SYMBOL + sessionEnergy;
            } else if (this.control.getMeterIsConnected && this.states.isCharging) {
                result = sessionEnergy;
            } else if (this.control.getCpIsConnected && this.states.isCharging) {
                result = sessionPower;
            } else if (this.states.isPaused) {
                result = _("controls.wallbox2.paused") + SEPARATOR_SYMBOL + sessionEnergy;
            } else if (this.states?.isCharging) {
                result = _("controls.wallbox2.charging-active");
            } else if (this.control.carConnectedInfoAvailable) {
                if (this.states.carConnected) {
                    result = _("controls.wallbox2.vehicle-connected");
                } else {
                    result = _("controls.wallbox2.no-vehicle");
                }
            }
            return result;
        }

        _updateDuration(viaInterval = false) {
            if (this.states.session && this.states.session.connect > 0 && this.states.carConnected) {
                this.states.durationSecs = moment().unix() - this.states.session.connect;
            } else {
                this.states.durationSecs = 0;
            }
            if (viaInterval) {
                this.version++; // count up version with each update
                this.notifyListener();
            }
        }

        _prepareModesMap(modesText) {
            let modesList, modesMap = {};
            try {
                if (modesText.length > 2 && modesText[0] !== "[") {
                    modesText = "[" + modesText + "]";
                }
                modesList = JSON.parse(modesText);
                modesList.forEach((mode) => {
                    modesMap[mode.id] = mode;
                });
            } catch (ex) {
                console.error(this.name, "Failed to parse modes from state!", modesText);
            }
            return modesMap;
        }

        /**
         * Parses, validates and potentially adopts the session info received from the MS
         * @param sessionText
         * @returns {{}}
         * @private
         */
        _prepareSession(sessionText) {
            let session;
            if (sessionText === "") {
                return null; // no session.
            }
            try {
                /*
                connect: unix timestamp of vehicle connection
                disconnect: unix timestamp if vehicle disconnection
                start: unix timestamp of last enabling of charge
                energy: total energy charged in this session
                power: current charging power
                user: userID for current charging session
                price: *since 14.5* price of the session
                 */
                session = JSON.parse(sessionText)

            } catch (ex) {
                console.log(this.name, "_prepareSession: Failed to parse session! text = '" + sessionText + "'", ex);
                session = null;
            }

            return session;
        }

        _onTrackerStateChanged(trackerStates) {
            let lastSessionEntry, dateEntry;
            if (trackerStates.trackerEntries) {
                lastSessionEntry = this._convertTrackerEntry(trackerStates.lastEntry);
                this.states.lastSession = lastSessionEntry ? lastSessionEntry.session : null;

                this.states.sessionHistory = [];
                let numEntriesTotal = 0;
                trackerStates.trackerEntries.forEach((dateListEntry) => {
                    dateEntry = Object.assign({}, dateListEntry);
                    dateEntry.entries = [];
                    dateListEntry.entries.forEach((dayEntry) => {
                        dateEntry.entries.pushObject(this._convertTrackerEntry(dayEntry));
                    });
                    if (dateEntry.entries.length > 0) {
                        numEntriesTotal += dateEntry.entries.length;
                        this.states.sessionHistory.push(dateEntry);
                    }
                });

                // safety net for issues on MS, if the lastEntry-session was invalid try, to look one up from the sessionHistory
                if (!this.states.lastSession && this.states.sessionHistory.length > 0) {
                    this.states.lastSession = this.states.sessionHistory[0].entries[0].session;
                }

                // the control content must show a tracker as soon as there is more than 1 entry. as the sessionHistory
                // aggregates multiple sessions of one day into one entry, the history must still be shown, even if there
                // is only one sessionHistory-entry - but it may include two sessions on that day.
                this.states.needsHistory = numEntriesTotal > 1;

                this.version++; // count up version with each update
                this.notifyListener();
            } else {
                this.states.needsHistory = false;
            }
        }

        _convertTrackerEntry(entry) {
            if (!entry) {
                console.log(this.name, "_convertTrackerEntry: no entry!", entry);
                return null;
            } else if (!entry.text) {
                console.log(this.name, "_convertTrackerEntry: no text in entry!", entry);
                return null;
            }

            let result = {},
                sessionInfo;

            sessionInfo = this._prepareSession(entry.text);
            if (!sessionInfo) {
                return null;
            }
            result.time = entry.time;
            result.timestamp = entry.timestamp;
            result.text = entry.text;
            result.session = sessionInfo;

            let durationText = LxDate.formatSecondsShort(sessionInfo.disconnect - sessionInfo.connect, true, true, false);
            let energyText = lxUnitConverter.convertAndApply(this.control.totalFormat, sessionInfo.energy);
            let userIdText = sessionInfo.user;
            result.text = durationText + ", " + energyText + (userIdText ? (", User: " + userIdText) : "");

            return result;
        }

    };
});
